import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/downtown-san-diego-urban-living-at-its-finest"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Downtown San Diego_ Urban Living at Its Finest.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                While once a neglected and struggling urban core, 50 years and a slew of concerted
                revitalization efforts have transformed San Diego into a thriving community
                embodying urban living at its finest.
              </CommonParagraph>
              <CommonParagraph>
                Our city is a shining example of what can happen when local government, businesses,
                and residents work together. From pristine beaches and a vibrant nightlife to a
                thriving arts and culture scene, here are only a few reasons to consider moving to
                downtown San Diego.
              </CommonParagraph>
              <Heading type="h2">Natural Beauty</Heading>
              <CommonParagraph>
                It’s rare for a city to have the perfect balance of convenience, culture, and
                natural beauty. San Diego offers residents all that and more.
              </CommonParagraph>
              <CommonParagraph>
                Our city center is only steps away from San Diego Bay and what we would argue are
                some of the most beautiful beaches in the country. The list of favorites is long,
                but La Jolla Cove, Mission Beach, and gems like Torrey Pines State Beach and Sunset
                Cliffs are must-sees.
              </CommonParagraph>
              <CommonParagraph>
                Do you prefer the mountains? You don’t have to travel far to reach the Cuyamaca and
                Laguna Mountains for some hiking, camping, or wildlife viewing. And if you don’t
                mind driving an hour and a half inland, you’ll find a vast desert, jutting cliffs,
                and slot canyons at Anza Borrego Desert State Park.
              </CommonParagraph>
              <CommonParagraph>
                The choices are endless, and the landscape changes dramatically, no matter which
                direction you point to on the map.
              </CommonParagraph>
              <Heading type="h2">Endless Dining Options</Heading>
              <CommonParagraph>
                Thanks to its diverse population, San Diego has become a culinary paradise, offering
                a range of cuisines, including Mexican, Italian, French, Spanish, Thai, Filipino,
                Greek, Vietnamese, and much more. Our city also has the awards and recognition to
                show for it.
              </CommonParagraph>
              <CommonParagraph>
                San Diego landed on Tripadvisor and Wine Magazine’s Top 10 Lists of food
                destinations in the United States. Thrillist Travel even called San Diego one of the
                “Best New Foodie Cities in America.”
              </CommonParagraph>
              <CommonParagraph>
                We should also mention that our city is home to five Michelin-star-awarded
                restaurants. Plus, we’re only 17 miles from the Mexican border, which opens up even
                more opportunities to expand your palate—not to mention the possibilities for
                adventure.
              </CommonParagraph>
              <Heading type="h2">Rich Cultural Scene</Heading>
              <CommonParagraph>
                Cinemas, particularly those featuring foreign and indie films, are disappearing at
                an alarming rate. But not in San Diego.
              </CommonParagraph>
              <CommonParagraph>
                Whether you want to take in the latest superhero flick at the AMC or support
                historic preservation while screening an indie film at Vintage Village or Hillcrest
                Cinemas, the choice is yours. But this doesn’t even scratch the surface.
              </CommonParagraph>
              <CommonParagraph>
                Our city is also home to The San Diego Museum of Art, which houses a collection of
                works by heavy-hitters like Salvador Dalí, Henri Matisse, Claude Monet, Georgia
                O’Keeffe, Diego Rivera, and others.
              </CommonParagraph>
              <CommonParagraph>
                Live music is also everywhere, whether in the local dive, gastropub, or
                award-winning restaurant. Plus, the San Diego Symphony presents a diverse lineup of
                concerts and events. There’s a concert for every taste from Puccini, Mendelssohn,
                and Yo-Yo Ma to Ziggy Marley and Paul Simon.
              </CommonParagraph>
              <Heading type="h2">Diverse Architecture</Heading>
              <CommonParagraph>
                Real estate is our specialty, and thankfully, we live in a city that offers a mix of
                architecture that caters to a wide range of tastes. The Gaslamp Quarter, known for
                its shopping, dining, museums, hotels, and live music venues, is also home to
                numerous Victorian-era buildings from the 19th and early 20th centuries.
              </CommonParagraph>
              <CommonParagraph>
                Just east of the Gaslamp Quarter, the East Village offers a mix of historic and
                modern buildings. Little Italy offers charm, food, shopping, and Italian
                Renaissance-inspired architecture. If you prefer modern architecture, visit the
                Marina District, where sleek high-rises dot the waterfront.
              </CommonParagraph>
              <CommonParagraph>
                Art Deco, mid-century modern, postmodern, contemporary, and even the lingering
                influence of Spanish Colonial Revival architecture can also be found downtown and in
                <BlogLink
                  url="https://selbysellssd.com/best-places-to-live-in-san-diego/"
                  text="surrounding neighborhoods"
                />
                . Neighborhoods like Bankers Hill, Hillcrest, North Park, and University Heights
                land at the top of the list. Each neighborhood is unique, but they are only a
                stone's throw away from the bigger city action.
              </CommonParagraph>
              <Heading type="h2">We Will Move Mountains to Move You Into Your Dream Home</Heading>
              <CommonParagraph>
                Are you looking for urban living at its finest? We can help you find it. Whether
                you're looking for the vibrant downtown lifestyle in the heart of the Gaslamp
                Quarter or the unique charm of surrounding neighborhoods like Bankers Hill,
                Hillcrest, North Park, or University Heights, our experienced and dedicated team is
                here to help you find your dream home.{" "}
                <ContactSlideoutLink text="Contact us today" /> !
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
